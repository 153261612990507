.border {
    border-color: #C8E1FF;
    border-style: solid;
    background-color: #F1F8FF;
    border-radius: 10px;
    border-width: 2px;
}

.RemoveHyperLinkDecorations {
    color: black;
    text-decoration: none; /* no underline */
  }