.actorLicensePreview {
    display: flex;
    flex-direction: row;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-wrap: nowrap;
}

.actorLicensePreview.small {
    flex-direction: column;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
}

.actorLicensePreview div:first-child,
.actorLicensePreview.small div:first-child {
    margin-left: 0;
    margin-top: 0;
}

.actorLicensePreview div {
    margin-left: 16px;
}
.actorLicensePreview.small div {
    margin-left: 0px;
    margin-top: 16px;
}

.actorLicensePreview.edit svg text {
    pointer-events: none;
}
.actorLicensePreview.edit svg rect {
    cursor: pointer;
}
.actorLicensePreview.edit svg rect.textBox {
    fill: rgba(105, 175, 255, 0.3);
}
.actorLicensePreview.edit svg rect.textBox:hover {
    fill: rgba(105, 175, 255, 0.5);
}
.actorLicensePreview.edit svg rect.textBox.selected,
.actorLicensePreview.edit svg rect.textBox:active {
    fill: rgba(105, 175, 255, 0.8);
}

#actorLicensePrintView {
    display: none;
}

@media print {
    #actorLicensePrintView {
        display: flex;
        width: 100%;
    }

    .actorLicensePreview.small div,
    .actorLicensePreview div {
        margin-top: 0;
        margin-left: 0;
        page-break-after: always;
    }

    .actorLicensePreview {
        flex-direction: column;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
    }

    .actorLicensePreview image {
        clip-path: none;
    }
}