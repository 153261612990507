/* 
Max width before this PARTICULAR table gets nasty
This query will take effect for any screen smaller than 760px
and also iPads specifically.
*/
.studentToolBar {
  min-height: 72px;
  position: relative;
  background-color: white;
  z-index: 1;
  /* margin-left: 43px; */
  top: 72px;
  margin-top: -72px;
  background-color: #e6f1fa;
  padding-left: 58px;
}
.partToolbar,
.orderToolBar {
  min-height: 72px;
  background-color: #e6f1fa;
}

.studentTable thead {
  padding: 0px;
  height: 72px;
}
.studentTable thead tr {
  height: 72px;
}
.studentTable tr {
  height: 81px;
}
.studentTable th {
  padding: 0px;
  /* font-size: 16px; */
}
.studentTable th:first-child {
  padding-left: 8px;
}
.studentTable td {
  padding-left: 0px;
  /* font-size: 16px; */
}
.studentTable td:first-child {
  padding-left: 8px;
  width: 1%;
}
.studentTable:last-child th,
.studentTable :last-child td {
  border-bottom: 0;
}
.studentTable tr {
  border-bottom: 1px solid #fafafa;
}
.studentTable tr:first-child {
  border-top: 1px solid #fafafa;
}
.studentTable tr:last-child {
  border-bottom: none;
}

.studentTable th {
  width: 13%;
}
.studentTable th:nth-of-type(1) {
  /*width: 20%;*/
  width: auto;
}
.studentTable th:nth-of-type(2) {
  width: 25%;
}
.studentTable th:nth-of-type(9) {
  width: auto;
}
.bookingPaper {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  /* border-radius: 8px; */
  background-color: inherit;
  transition: none;
}

.bookingCard {
  flex-grow: 1;
  min-width: 300px;
}
.bookingBox > div {
  display: flex;
  align-items: center;
  gap: 16px;
  line-height: 12px;
  margin-bottom: 16px;
}
.bookingBox > div:last-child {
  margin-bottom: 0;
  padding: 0;
}
.bookingBox > div > svg {
  margin-left: 8px;
}
.gridNoTopSpace {
  padding-top: 0 !important;
  margin-top: 0 !important;
  /* min-height: auto !important; */
}

body {
  color: rgba(0, 0, 0, 0.87);
}
h1 {
  font-size: 32px;
  line-height: 1.25;
  letter-spacing: 0.25px;
  font-weight: normal;
}
h2 {
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
}
.absoluteRTI {
  display: flex;
  justify-content: space-between;
}
.absoluteRTI > div:first-child {
  flex-grow: 1;
}
.absoluteRTI > div:last-child {
  padding-top: 10px;
}

.statBox {
  display: flex;
  justify-content: space-between;
  height: auto;
  margin-top: 16px;
  margin-bottom: 16px;
  gap: 16px;
  flex-wrap: wrap;
}
.statBox > div {
  background: white;
  border-radius: 4px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 32px;
  flex-grow: 1;
  gap: 16px;
  border-radius: 8px;
  width: 400px;
  box-shadow: 0 0 2px 0 rgba(145, 158, 171, 0.24),
    0 16px 32px -4px rgba(145, 158, 171, 0.24);
}
.statBox .customCP {
  height: 96px !important;
  width: 96px !important;
}
@media only screen and (max-width: 1700px) {
  .statBox > div {
    width: 45%;
  }
}
@media only screen and (max-width: 900px) {
  .statBox > div {
    padding: 16px;
    width: 300px;
  }
  .statBox .customCP {
    height: 48px !important;
    width: 48px !important;
  }
  .statBox h1 {
    font-size: 24px;
  }
}
.courseInfo {
  /* display: flex; */
  /*  justify-content: space-between; */
  margin-bottom: 16px;
  grid-gap: 8px;
  margin-top: 40px;
  display: grid;
  /* grid-template-columns: 3fr 2fr 2fr 0.5fr 0.5fr; */
  grid-template-columns: 3fr 2fr 2fr 2fr 0.5fr;
}
.courseInfo.baseInfo {
  margin-bottom: 32px;
  margin-top: 0px;
}

.courseInfo > div {
  display: flex;
  align-items: center;
  gap: 10px;
}
.courseInfo > div:last-child {
  justify-self: end;
  grid-column-end: span 2;
}

/* .courseInfo > div:nth-child(1) {
  background-color: red;
}
.courseInfo > div:nth-child(2) {
  background-color: yellow;
}
.courseInfo > div:nth-child(3) {
  background-color: green;
}
.courseInfo > div:nth-child(4) {
  background-color: aqua;
} */
.courseInfo > div:nth-child(5) {
  /*  background-color: orange; */
  grid-column-end: span 1;
}

.courseInfo > div > * {
  display: flex;
  flex-direction: column;
}
.courseInfo > div > * > * {
  display: flex;
  gap: 10px;
  line-height: normal;
  align-items: flex-end;
}
.courseInfo.baseInfo > div > * > * {
  min-height: 26px;
}

.courseInfo div:first-child img {
  width: 60px;
  height: 60px;
}

@media only screen and (max-width: 1000px) {
  .courseInfo {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }
  .courseInfoDots {
    position: absolute;
    right: 20px;
  }
  /*  grid-column-start: 2;
grid-row-start: 1; */
}
@media only screen and (max-width: 700px) {
  .courseInfo {
    grid-template-columns: none;
    grid-template-rows: auto;
  }
}

body {
  font-family: Roboto;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.25px;
}
.flexgrow1 {
  flex-grow: 1;
}
.stdInfoRect,
.filetype,
.planerad,
.pagaende,
.avslutad {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  text-transform: uppercase;
  font-size: 12px;
  border-radius: 4px;
}

.stdInfoRect.shadowEdge {
  border: 2px solid white;
  border-radius: 6px;
  margin-left: -2px;
}

.iconbox.blue,
.textbox.grades-done,
.textbox.blue,
.planerad {
  color: #006ec8;
  background-color: rgb(0, 110, 200, 0.2);
}
.textbox.grades-ongoing,
.pagaende {
  color: #fab400;
  background-color: rgb(250, 180, 0, 0.2);
}
.textbox.grades-licensed,
.avslutad {
  color: #14b496;
  background-color: rgb(20, 180, 150, 0.2);
}
.iconbox.warning,
.warning {
  color: rgba(240, 80, 50, 1);
  background-color: rgba(240, 80, 50, 0.1);
}
.warning.nobackground {
  color: rgba(240, 80, 50, 1);
  background-color: transparent;
}
.iconbox.success,
.success {
  color: rgba(20, 180, 150, 1);
  background-color: rgba(20, 180, 150, 0.1);
}
.iconbox.darkgray {
  /* color: rgba(20, 180, 150, 1); */
  /* bac1kground-color: rgba(20, 180, 150, 0.1); */
  color: #696969;
  background-color: #e9e9e9;
}
.filetype {
  gap: 8px;
  color: #797979;
  background-color: #eff0f0;
}
.iconbox {
  padding: 2px 8px 2px 8px;
  border-radius: 4px;
  opacity: 0.7;
  background-color: rgba(0, 0, 0, 0.1);
  width: auto;
  margin-left: 2px;
}
.iconbox.opaque {
  opacity: 1;
}
.iconbox.lower {
  margin-top: 8px;
}
.iconbox.mini {
  width: 30px;
  height: 20px;
}

.warningColor {
  color: rgba(240, 80, 50, 1);
}

.textbox {
  /* Nygammal textbox dom default I de fallen textbox verkligen skall vara grå och utan border */
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.07);
}

.textbox.selectable {
  /* border-radius: 4px; */
  border: solid 1px rgba(0, 0, 0, 0.23);
}
.textbox.selectable:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.textbox.selected {
  border: 1px solid #006ec8;
  background-color: rgba(0, 110, 200, 0.0348);
  color: #006ec8;
}

.textbox.selected p {
  color: rgba(0, 110, 200, 0.6);
}

.selectable {
  cursor: pointer;
}

.iconAndText {
  display: flex;
}
.iconAndText > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.courseImage {
  height: 48px;
  width: 48px;
  min-width: 48px;
  margin-right: 16px;
  /* margin-left: 8px; */
  border-radius: 4px;
}

.bookingTypeTitleContainer {
  width: 126px;
  height: 64px;
  margin: 24px 24px 0px;
  overflow: hidden;
}

.bookingTypeTitleContainer.small {
  width: auto;
  height: 32px;
}

.bookingTypeInfoContainer {
  width: 126px;
  height: 128px;
  margin: 0px 24px 24px;
  overflow: hidden;
}

.bookingTypeInfoContainer.small {
  width: auto;
  height: auto;
}

.skillImage {
  height: 68px;
  width: 141px;
}

.skillImage.small {
  width: 108px;
}

.skillImage.disabled {
  opacity: 0.5;
}

/* Alla regler för ".skillItem" har även för ".stdDialog .MuiPaper-root.skillItem",
   för att vara mer specifik än annan regel som annars stör */
.stdDialog .MuiPaper-root.skillItem,
.skillItem {
  background: rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  padding: 8px 16px;
  width: 172px;
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  white-space: normal;
  line-height: 1;
  height: 140px;
}

.stdDialog .MuiPaper-root.skillItem.small,
.skillItem.small {
  padding: 16px 16px 8px;
  width: 140px;
}

.stdDialog .MuiPaper-root.skillItem.selected,
.skillItem.selected {
  /*TODO Borde nog vara border egentligen. Men får inte rätt på layouten då*/
  outline: solid 1px #006ec8;
  background-color: rgba(0, 110, 200, 0.0348);
}

.stdDialog .MuiPaper-root.skillItem.selected p,
.skillItem.selected p {
  color: rgba(0, 110, 200, 0.6);
}

.stdDialog .MuiPaper-root.skillItem.selected.disabled,
.skillItem.selected.disabled {
  /*TODO Borde nog vara border egentligen. Men får inte rätt på layouten då*/
  outline: solid 1px #006ec867;
  background-color: rgba(0, 110, 200, 0.02);
}

.stdDialog .MuiPaper-root.skillItem.selected.disabled p,
.skillItem.selected.disabled p {
  color: rgba(0, 110, 200, 0.3);
}

.stdDialog .MuiPaper-root.skillItem.failed,
.skillItem.failed {
  background-color: rgba(220, 0, 0, 0.17);
}

.stdDialog .MuiPaper-root.skillItem.failed.selected,
.skillItem.failed.selected {
  /*TODO Borde nog vara border egentligen. Men får inte rätt på layouten då*/
  outline: solid 1px #ec0303;
}

.stdDialog .MuiPaper-root.skillItem.failed.selected p,
.skillItem.failed.selected p {
  color: rgba(220, 0, 0, 0.7);
}

.gradesTable {
  margin-bottom: 50px;
  min-width: 500px; /* TODO försäkra dig om att mobil-läge också hanteras*/
  white-space: nowrap;
}
.gradesTable.gradesTableSmall {
  min-width: auto;
}
.gradesTable.licenseReg {
  margin-bottom: 12px;
}
.gradesTable.id06 {
  margin-bottom: 0px;
}

.gradesTableHeader th {
  border-bottom: 0;
  background-color: #e6f1fa;
}

.gradesTableHeader.licenseReg th,
.gradesTableRow.licenseReg th,
.gradesTableRow.licenseReg td,
.gradesTableHeader th,
.gradesTableRow th,
.gradesTableRow td {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}

.gradesTableHeader.licenseReg th:last-child,
.gradesTableRow.licenseReg td:last-child {
  border-right: 0;
}

.gradesTableHeader.small th,
.gradesTableRow.small th,
.gradesTableRow.small td {
  border: 0;
}

.gradesTableHeader th:first-of-type,
.gradesTableRow th:first-of-type {
  padding-left: 24px;
  padding-right: 14px;
}
.gradesTableRow .avatarInfo {
  max-width: 200px;
  overflow: hidden;
}

.gradesTableRow.licenseReg th:first-of-type,
.gradesTableRow.small th:first-of-type {
  padding-left: 16px;
  padding-right: 12px;
}
.gradesTableRow.licenseReg th:first-of-type > div,
.gradesTableRow.small th:first-of-type > div {
  max-width: 55vw;
  overflow: hidden;
}

.gradesTableRow.small td {
  padding-left: 12px;
  padding-right: 12px;
}

.gradesTableHeader.licenseReg th,
.gradesTableRow.licenseReg td {
  padding-left: 0px;
  padding-right: 0px;
}

.gradesTableHeader th:last-child,
.gradesTableRow td:last-child {
  border-right: 0;
  /*  padding-left: 24px;
  padding-right: 24px; */
}

.gradesTableRow.licenseReg td:last-child,
.gradesTableRow.small td:last-child {
  padding-left: 0;
  padding-right: 12px;
}

.gradesTableHeader.licenseReg th:last-child,
.gradesTableHeader.small th:last-child {
  border-right: 0;
  padding-left: 0;
  padding-right: 0;
}

.externalSkillCheckbox,
.gradesTableSkillCheckbox {
  /* margin: 0px 8px; */
  margin: 0px 4px;
}

.gradesTableSkillCheckbox.licenseReg {
  margin: 0px 0px;
}

.externalSkillCheckbox {
  max-width: 100px;
}

.externalSkillCheckboxLabel {
  max-width: 100px;
  text-overflow:ellipsis;
  overflow:hidden;
  white-space:nowrap;
}

.cardHeader.licenseReg {
  padding-top: 24px;
  padding-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
}

.studentGradesAvatarContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin-top: 12px;
  margin-bottom: 24px;
}

.studentGradesSkillCheckbox {
  margin: 16px 24px 0px;
}

.studentGradesLicenseDateContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 24px 0px 12px;
}

.finalScore {
  padding: 3px;
  border-radius: 4px;
  font-size: 10px;
  /*background-color: #14b496;*/
  width: min-content;
  color: white;
}
.stdFlexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
}
.skillItems.stdFlexCenter > span {
  width: 172px;
  height: 0;
  flex-grow: 1;
}
.skillItems.stdFlexCenter > .skillItem {
  flex-grow: 1;
}

.skillItems.stdFlexCenter > .skillItem .skillImage {
  width: 80%;
  margin: auto;
  height: 85px;
  /* height: auto;
	max-height: calc(100% - 20px); */
}
.skillItems.stdFlexCenter > .skillItem p {
  font-size: 12px;
  line-height: 1;
  padding-top: 4px;
}
.skillItems.skillItemsConnect > .skillItem {
  cursor: pointer;
  min-width: auto;
}
.skillItems.skillItemsConnect > .skillItem:hover {
  outline: solid 1px #006ec8;
  background-color: rgba(0, 110, 200, 0.0348);
}
/* .skillItems.skillItemsConnect >.skillItem.selected {
  outline: solid 1px #006ec8;
  background-color: rgba(0, 110, 200, 0.0348);
} */

.stdFlexCol {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.stdFlexCenterCol {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-direction: column;
}
.bigActorAvtar {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  flex-direction: column;
  margin-bottom: 48px;
}

.dataGridEmptyOverlay {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}

.stdFlexLeft {
  display: flex;
  align-items: center;
  gap: 8px;
}
.stdFlexLeft16 {
  display: flex;
  align-items: center;
  gap: 16px;
}
.stdFlexLeft0 {
  display: flex;
  align-items: center;
}
.stdFlexRight {
  display: flex;
  justify-content: flex-end;
}
.stdFlexLeftCenter {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
.profileActiveNav {
  cursor: pointer;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 22px;
  padding-right: 20px;
}

.profileActiveNav:hover {
  background-color: rgba(145, 158, 171, 0.08);
}
.MuiDataGrid-columnHeader .stdFlexLeft {
  font-weight: bold;
}

.stdListType {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  margin-bottom: 24px;
  line-height: 1.5;
}
/*
  Originalet, om det blir knas på något ställe
 .stdFlexRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  min-height: 64px;
} */

.stdFlexRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  margin-bottom: 16px;
  margin-top: 40px;
}
.stdFlexRowTop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  margin-bottom: 16px;
  margin-top: 0;
}
.stdFlexBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  flex-wrap: wrap;
}
@media only screen and (min-width: 500px) {
  .stdFlexBetween.timePickerWrap {
    flex-wrap: nowrap;
  }
}
.stdPage {
  /* margin-top: ??px; */
  /* padding: 24px; */
}
.stdPageTitle {
  font-size: 24px;
  line-height: 1;
  margin-bottom: 40px;
}

.stdCardHolder {
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
}

.stdCard {
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 16px;
  justify-content: space-between;
  box-shadow: 0 0 2px 0 rgba(145, 158, 171, 0.24),
    0 16px 32px -4px rgba(145, 158, 171, 0.24);
  border-radius: 8px;
  width: 100%;
}
.stdBoxShadow {
  box-shadow: 0 0 2px 0 rgba(145, 158, 171, 0.24),
    0 16px 32px -4px rgba(145, 158, 171, 0.24);
}
.stdCardHeader {
  font-weight: 700;
  font-size: 1.125rem; /*18px*/
}
.stdCardBody {
  /*  Kanske behövs? */
}
.stdCardInnerBox {
  border: 1px solid #00000014;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px;
  /*  ...Kanske behövs? */
}
.stdCardFooter {
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.stdFlex {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 0;
}

.stdFlexSB {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.avatarInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
}
.avatarInfo > div:first-child {
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500;
  font-size: 16px;
}
.avatarInfo .MuiTypography-root {
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
}

.actorInfoStack {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
}
.actorInfoStack > div:first-child {
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
}

.loginLogo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  /* margin: 32px; */
}
.loginLogo img {
  /*  width: 168px;
  height: 30px; */
}
.loginCompany {
  display: flex;
  flex-direction: column;
}
.itemRow {
  width: 100%;
  border: 1px solid silver;
  margin-bottom: 2px;
  border-radius: 8px;
}
.itemRow > button {
  width: 100%;
}
.langIcons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin: 24px;
}
.langIcons img {
  opacity: 0.5;
}
.langIcons > .langSelected img {
  opacity: 1;
}
.langIcons > .langSelected {
  outline: 1px solid rgb(0, 77, 140);
  outline-offset: 2px;
}
.loginPage {
  background-color: black;
  background-image: url("../public/static/bgimage.png");
  background-size: cover;
  /*  height: 100vh; */
  min-height: 100vh;
}

.loginPagePermits {
  background-color: black;
  background-image: url("../public/static/Körtillstånd bakgrund.png");
  background-size: cover;
  /*  height: 100vh; */
  min-height: 100vh;
}

.loginPagePermitsMobile {
  background-color: black;
  background-image: url("../public/static/Körtillstånd bakgrund mobil.png");
  background-size: cover;
  /*  height: 100vh; */
  min-height: 100vh;
}

.loginBox {
  background-color: white;
  padding: 24px;
  border-radius: 8px;
  min-height: 330px;
}
.leftDDMenu li {
  gap: 8px;
}
.MuiDataGrid-cell {
  cursor: pointer;
}
.MuiDataGrid-columnHeader:focus-within,
.MuiDataGrid-cell:focus {
  outline: 0 !important;
}
.fullWidth {
  max-width: unset;
}
.MuiDataGrid-columnSeparator--sideRight {
  display: none !important;
}
.MuiDataGrid-columnHeader:first-child,
.MuiDataGrid-cell:first-child {
  padding-left: 24px;
  /* background-color: red; */
}

@media only screen and (max-width: 700px) {
  .MuiDataGrid-cell,
  .MuiDataGrid-row {
    max-height: none !important;
  }
}
.dgNoFilter .MuiDataGrid-toolbarContainer {
  display: none !important;
}
.hideStdFilter {
  display: none;
}
.visibilityHidden {
  visibility: hidden;
}
.dgSmall .MuiDataGrid-toolbarContainer {
  display: none !important;
}
.dgSmall .MuiDataGrid-cell,
.dgSmall .MuiDataGrid-row {
  max-height: none !important;
}
.MuiTabs-flexContainer.MuiTabs-centered {
  flex-wrap: wrap;
}
.tabFixFlow {
  min-height: unset;
  padding: 8px;
}
.dgCard {
  width: 100%;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.dgCard > div {
}
.dgCard > div b {
  min-width: 150px;
  margin-right: 8px;
  display: inline-block;
  vertical-align: top;
}
.dgCard > div > b::after {
  content: ":";
}
.dgCard button {
  float: right;
}
.cspremits .MuiDataGrid-virtualScrollerContent {
  min-height: 270px;
}
.cslicenses .MuiDataGrid-virtualScrollerContent {
  min-height: 270px;
}
.customSpeedDial {
  position: fixed;
  bottom: 8px;
  /* right: 8px; */
  right: 48px;
}
@media only screen and (max-width: 1200px) {
  .customSpeedDial {
    right: 24px;
  }
}

.MuiDataGrid-row.disabled {
  pointer-events: none;
  color: #637381b2;
}

.customSpeedDial .MuiSpeedDialAction-staticTooltipLabel {
  white-space: nowrap;
  min-width: 200px;
  cursor: pointer;
}
.stdMenuPos {
  position: absolute;
  top: 8px;
  right: 8px;
}
.menuItemDisabled {
  opacity: 0.2;
  pointer-events: none;
}
/* .MuiDialog-container> .MuiPaper-root */
.MuiDialog-container .MuiPaper-root {
  /* margin: 8px; */
  margin: 0;
  margin-right: 8px;
  margin-left: 8px;
}
.MuiDialog-container .noPaperMargin.MuiPaper-root {
  margin: 0;
  min-width: auto;
  max-width: none;
}
.MuiDialog-container .MuiPaper-root.skillItem {
  margin: 0;
}
.MuiDialog-container .MuiPaper-root.advancedSettingsAccordion {
  margin-left: 0;
  margin-right: 0;
  min-width: 0;
}
.MuiModal-root.yesOrNoDialog .MuiPaper-root {
  min-width: 250px;
}

.MuiDataGrid-menu,
.customColumnPanel,
.customfilterPanel {
  box-shadow: none;
  box-shadow: 0px 5px 5px -3px rgba(145, 158, 171, 0.2),
    0px 8px 10px 1px rgba(145, 158, 171, 0.14),
    0px 3px 14px 2px rgba(145, 158, 171, 0.12);
  border-radius: 8px;
}
.customfilterPanel .MuiDataGrid-filterFormOperatorInput {
  display: none;
}

/* Tabell filter fixar  */
.memToolBar {
  padding: 16px;
  /* float:right; */
}
.memToolBar > button {
  padding-right: 16px;
}
.customPanel {
  top: -89px !important;
  left: 15px !important;
  border-radius: 8px;
}
.customPanel .MuiDataGrid-panelHeader {
  display: none;
}
.customPanel .MuiFormControlLabel-root {
  margin-left: 2px;
  gap: 8px;
}
.customPanel .MuiFormControlLabel-root.Mui-disabled {
  display: none;
}

.shiftDatagridToolbar .MuiDataGrid-toolbarContainer {
  padding-left: 16px;
}

/* ---------------------- */

.stdForm {
  display: flex;
  gap: 16px;
  flex-direction: column;
  width: 100%;
  /*min-width: 320px;*/
  /*width: 600px;*/
}
.stdForm > div {
  display: flex;
  align-items: center;
  gap: 8px;
}
.stdForm .stdFlexSB {
  gap: 8px;
}
.twoColForm {
  display: flex;
  gap: 32px;
  align-items: flex-start;
}
.stdDialogContent {
  min-width: 250px;
}
.dialogOverlay {
  opacity: 1;
}
/*smallHeader = OVERLINE TEXT*/
.smallHeader {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  text-transform: uppercase;
  margin-top: 8px;
}
.smallHeader.camelCase {
  text-transform: none;
}
.smallHeader.blue,
.smallHeader.button {
  color: #006ec8;
}
.smallHeader.button.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.smallHeader .flexTest {
  display: flex;
}
.passwordBalance {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 8px;
}
.passwordBalance > div {
  display: flex;
  gap: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.passwordBalance > div > div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 12px;
  flex-grow: 1;
  max-width: 100px;
  min-width: 100px;
}
.passwordBalance > div > div > div {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  width: 100%;
  text-align: center;
  font-size: 24px;
  background-color: rgba(179, 15, 11, 0.1);
  color: rgba(179, 15, 11, 0.87);
  border-radius: 4px;
  font-weight: bold;
  margin-bottom: 16px;
}
/* 
.passwordBalance > div > div:nth-of-type(1) > div {
  background-color: rgba(179, 15, 11, 0.1);
  color: rgba(179, 15, 11, 0.87);
}
.passwordBalance > div > div:nth-of-type(1) > div {
  background-color: rgba(90, 102, 122, 0.1);
  color: rgba(90, 102, 122, 0.87);
}
.passwordBalance > div > div:nth-of-type(3) > div {
  background-color: rgba(105, 55, 46, 0.1);
  color: rgba(105, 55, 46, 0.87);
} */
.hideEmpty:empty {
  display: none;
}

.formAvatar .MuiAvatar-circular {
  border: 1px solid rgba(145, 158, 171, 0.24);
}
.stdDialog .MuiDialogActions-root,
.bigCourseDialog .MuiDialogActions-root,
.mediumCourseDialog .MuiDialogActions-root,
.smallCourseDialog .MuiDialogActions-root {
  flex-wrap: wrap;
}
/* CustomCss för dialog*/
/* .bigCourseDialog .MuiDialogTitle-root,
.mediumCourseDialog .MuiDialogTitle-root,
.smallCourseDialog .MuiDialogTitle-root {
  border: 0px solid red;
}
.bigCourseDialog .MuiDialogContent-root,
.mediumCourseDialog .MuiDialogContent-root,
.smallCourseDialog .MuiDialogContent-root {
  border: 0px solid blue;
}
.bigCourseDialog .MuiDialogActions-root,
.mediumCourseDialog .MuiDialogActions-root,
.smallCourseDialog .MuiDialogActions-root {
  border: 0px solid green;
} */
.stdDialog {
  opacity: 1;
}
.stdDialog .MuiPaper-root {
  /* margin: 8px; */
  min-width: 400px;
}
.stdDialog .MuiPaper-root .MuiPaper-root {
  min-width: auto;
}

@media only screen and (max-width: 400px) {
  .stdDialog .MuiPaper-root {
    min-width: 97vw;
  }
}
.bigCourseDialog .MuiPaper-root {
  min-width: 800px;
}
@media only screen and (max-width: 800px) {
  .bigCourseDialog .MuiPaper-root {
    min-width: 97vw;
  }
}
.mediumCourseDialog .MuiPaper-root {
  min-width: 600px;
  max-width: 600px;
}
@media only screen and (max-width: 600px) {
  .mediumCourseDialog .MuiPaper-root {
    min-width: 97vw;
    max-width: 97vw;
  }
}
.smallCourseDialog .MuiPaper-root {
  min-width: 400px;
  max-width: 400px;
}
@media only screen and (max-width: 400px) {
  .smallCourseDialog .MuiPaper-root {
    min-width: 97vw;
    max-width: 97vw;
  }
}
/* -------------------- */
.smallInfoText {
  font-size: 12px;
  line-height: 1.67;
  letter-spacing: 0.4px;
  color: rgba(0, 0, 0, 0.6);
}

.noCloseButton .modalCloseButton {
  display: none;
}
.modalCloseButton {
  /* Om inga modaler ska använda default close button */
  display: none;
}
.MuiDialog-container .MuiPaper-root {
  box-shadow: none;
}
/* ---------------------- */
.centerSpan8 span {
  display: inline-flex;
  align-items: center;
  gap: 8px;
}

@media only screen and (max-width: 700px) {
  .stdDialog .MuiPaper-root {
    width: 97vw;
  }
  .twoColForm {
    flex-direction: column;
    align-items: stretch;
  }
  .twoColForm > div > div {
    align-items: start;
    flex-direction: column;
  }
  .twoColForm > .formAvatar > div {
    display: flex;
    align-items: center;
  }
  .stdForm > div > svg {
    display: none;
  }
  .stdDialogContent {
    padding: 16px 8px;
  }
  .dashboard {
    padding: 0;
    margin: 0;
  }
  /*padding: 16px 8px;
border-top: */
}
.flyer {
  position: relative;
  /*   top:-64px;
  height:600px; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 8px;
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.flyer > div {
  margin-bottom: 96px;
  padding-left: 16px;
  padding-right: 16px;
}
.flyer .topbox {
  padding: 24px;
  padding-top: 64px;
  height: 100%;
  /*  background-image: linear-gradient(to bottom, #e7f7f4, rgba(231, 247, 244, 0));  */
  background-image: linear-gradient(to bottom, #006ec8, rgba(231, 247, 244, 0));
  /*   background-image: linear-gradient(to bottom, #006ec859, rgba(231, 247, 244, 0)); */

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.flyer .topboxTitle {
  font-family: Roboto;
  font-size: 7vw;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: -1.5px;
  color: rgba(0, 0, 0, 0.87);
  text-align: center;
}
.flyer .topboxEport {
  font-family: Roboto;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #14b496;
}
.flyer .bodyBox {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 24px;
}

.flyer .boldBodyBox {
  max-width: 588px;
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.87);
}
.flyer .smallBodyBox {
  max-width: 588px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.87);
}
.flyer .cardHolder {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
  flex-wrap: wrap;
  max-width: 1100px;
}
.flyer .cardHolder > div {
  border-radius: 4px;
  /*   background-color: #e8f8f5; */
  background-color: #e6f0f9;
  width: 300px; /* 280px */
  height: 300px;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
  gap: 16px;
  padding: 16px; /*  8px; */
}
.flyer .cardHolder > div svg {
  font-size: 80px;
}

.flyer .cardHolder > div > div:nth-child(1) {
  /* color: #14b496; */
  color: #006ec8;
}
.flyer .cardHolder > div > div:nth-child(2) {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.87);
}
.flyer .cardHolder > div > div:nth-child(3) {
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.6);
}
.flyer .infoRow {
  display: flex;
  gap: 16px;
  flex-direction: column;
  max-width: 600px;
}
.flyer .infoRow > div {
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
}
.flyer .infoRow > div:nth-child(1) {
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.87);
}
.flyer .infoRow > div:nth-child(2) {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.87);
}
.flyer .infoRow > div > div {
  min-width: 96px;
}
.flyer .infoRow > div svg {
  font-size: 64px;
  color: rgba(0, 0, 0, 0.87);
}

.flyer .priceCardColorA .temaColor,
.flyer .priceCardColorA svg {
  color: #c8006e;
}
.flyer .priceCardColorA button {
  color: white;
  background-color: #c8006e;
}
.flyer .priceCardColorB .temaColor,
.flyer .priceCardColorB svg {
  color: #006ec8;
}
.flyer .priceCardColorB button {
  color: white;
  background-color: #006ec8;
}
.flyer .priceCardColorC .temaColor,
.flyer .priceCardColorC svg {
  color: #14b496;
}
.flyer .priceCardColorC button {
  color: white;
  background-color: #14b496;
}

.flyer .priceCardColorD .temaColor,
.flyer .priceCardColorD svg {
  color: #f05032;
}
.flyer .priceCardColorD button {
  color: white;
  background-color: #f05032;
}

.flyer .priceCardButton button:disabled,
.flyer .priceCardButton button[disabled] {
  /* TODO ingen aning hur dessa ska stylas. Vill bara att disabled ska synas preliminärt */
  background-color: #9d9d9d;
}

.flyer .priceCards {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
  align-items: stretch;
}
.flyer .priceCard {
  /* max-width: 300px; */
  max-width: 400px;
  background-color: white;
  padding: 24px;
  display: flex;
  flex-direction: column;
}
.flyer .priceCard > div {
  margin-bottom: 16px;
}
.flyer .priceCardTop,
.flyer .priceCardPrice {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;
}
.flyer .priceCardPrice {
  flex-grow: 1;
  justify-content: end;
}
.flyer .priceCardTop > div:nth-child(1),
.flyer .priceCardPrice > div:nth-child(1) {
  font-size: 32px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.25px;
}
.flyer .priceCardTop > div:nth-child(2),
.flyer .priceCardPrice > div:nth-child(2) {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 0.4px;
  text-align: center;
  color: rgba(0, 0, 0, 0.6);
}
.flyer .priceCardList > div {
  display: flex;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.87);
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  gap: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
}
.flyer .priceCardList > div:last-child {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.flyer .priceCardButton {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 700px) {
  .flyer .infoRow > div:nth-child(1) {
    flex-direction: column;
    align-items: flex-start;
    padding: 8px;
  }
  .flyer .infoRow > div > div {
    min-width: auto;
  }
  .flyer .topboxTitle {
    font-size: 8vw;
  }
  /* .flyer > div {
    padding-left: 8px;
    padding-right: 8px;
  } */
}
.flyer.iFramed .topbox,
.flyer.iFramed .bodyBox,
.flyer.iFramed .cardHolder,
.flyer.iFramed .infoRow {
  display: none;
}
.flyer.iFramed .priceCards {
  margin-top: 48px;
}

.height64 {
  height: 64px;
}
.squareIcon {
  /* border-radius: 4px; Ändrat till 8 (Henrik) */
  border-radius: 8px;
}

.toolBarBurger {
  /*  margin-right: 124px; */
  margin-right: 160px;
  padding: 12px;
  margin-left: -12px;

  /* position: absolute;
    padding: 12px;
    left:12px;  */
}

/* toolBarBurger för körtillstånd */
.toolBarBurgerKs {
  /*  margin-right: 124px; */
  margin-right: 160px;
  padding: 12px;
  margin-left: -12px;

  /* position: absolute;
    padding: 12px;
    left:12px;  */
}
.toolBarStack {
  /*max-width: 158px;*/
  /*   max-width: 222px; */

  width: 150px;
  display: flex;
  justify-content: flex-end;
  /* margin-right: -12px; */
  gap: 8px;
}
@media only screen and (max-width: 530px) {
  .toolBarBurger {
    margin-right: 0;
  }
  .toolBarStack {
    gap: 0;
  }
}

/* annan break point för toolBarBurger för körtillstånd */
@media only screen and (max-width: 780px) {
  .toolBarBurgerKs {
    margin-right: 0;
  }
}
.helpDrawer > .MuiPaper-root {
  /* top:60px; */
}

.actorsSearchForm {
  display: flex;
  gap: 16px;
}
.personRoles {
  padding: 0;
  margin: 0;
  height: 24px;
  width: 36px;
  text-align: center;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.1);
  margin-right: 4px;
}

.importDataGridContainer {
  margin-top: 12px;
  margin-bottom: 50px;
  min-width: 950px;
}

.importDataGridContainer.small {
  min-width: 400px;
}

.importDataGridContainer:last-child {
  margin-bottom: 12px;
}

.importDataGridContainer > h4 {
  margin-bottom: 16px;
}

.importDialogContent {
  min-width: 600px;
  max-width: 950px;
}

.importDialogContent.small {
  min-width: 300px;
  max-width: 600px;
}

.importDialogTargetFieldSection:first-child {
  margin-top: 18px;
}
.dialogAccordion .importDialogTargetFieldSection:first-child {
  margin-top: 0;
}

.importDialogTargetFieldSection .MuiInputBase-root {
  height: 65px;
}

.importDialogTargetFieldLabel {
  line-height: 2;
}

.MuiDialog-container .MuiPaper-root.advancedSettingsAccordion::before {
  opacity: 0;
}

td.shrink {
  width: 0.1%;
  white-space: nowrap;
}

.licensePrintDialogGrid.small td.hideOverflow > div {
  max-width: 55vw;
  overflow: hidden;
}

.licensePrintStudentList td {
  padding-left: 0;
  padding-right: 16px;
}
.licensePrintStudentList tr:first-child {
  border-top: 1px solid #fafafa;
}

.licensePrintDialogGrid {
  min-width: 800px;
  max-width: 1000px;
  width: 90vw;
  padding-bottom: 24px;
  white-space: nowrap;
}

.licensePrintDialogGrid.small {
  min-width: 300px;
  max-width: 600px;
  width: 90vw;
}

/* Needed a separate style selectListButton, because :hover
   messed up the .selected on mobile for some reason*/
.selectListButton,
.stUnitListItem,
.stdListButton {
  border-radius: 4px;
  border: solid 1px rgba(0, 0, 0, 0.23);
  margin-bottom: 8px;
}
.selectListButton .MuiListItemText-primary, 
.stdListButton .MuiListItemText-primary {
  font-weight: 600;
}
.selectListButton .MuiListItemText-secondary,
.stdListButton .MuiListItemText-secondary {
  font-size: 12px;
  font-weight: normal;
  color: inherit;
}
.stdListButton:hover {
  transition-property: all;
  transition-duration: 0.5s;
  background-color: #006ec81e;
  border-radius: 4px;
  border: solid 1px rgba(0, 110, 200, 0.23);
  color: rgba(0, 110, 200, 0.87);
}
.selectListButton.selected {
  transition-property: all;
  transition-duration: 0.5s;
  background-color: #006ec81e;
  border-radius: 4px;
  border: solid 1px rgba(0, 110, 200, 0.23);
  color: rgba(0, 110, 200, 0.87);
}

.stUnitListItem.selected {
  transition-property: all;
  transition-duration: 0.5s;
  background-color: #006ec81e;
  border-radius: 4px;
  border: solid 1px rgba(0, 110, 200, 0.23);
  color: rgba(0, 110, 200, 0.87);
}

.tabPanelNoPadding > .MuiBox-root {
  /* Bara direkta childs, kolla så det funkar */
  padding-left: 0;
  padding-right: 0;
}

.tableAvatar {
  display: flex;
  font-size: 12px;
  font-weight: 400;
  align-items: center;
  gap: 16px;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.6);
}
.tableAvatar > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.tableAvatar a {
  text-decoration: none;
}

.tableAvatar div.tableAvatarSubs a {
  /*  Om länkar finns i underrubrikerna, ha kvar underline */
  color: inherit;
  text-decoration: underline;
}
.tableAvatarTitle {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.87);
}

.tableAvatarTitle.bold {
  font-weight: 500;
}

.staticEmptyBox {
  margin: auto;
  display: flex;
  flex-direction: column;
  opacity: 0.6;
  gap: 8px;
}
.staticEmptyBox > div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 4px;
}
.staticEmptyBox > div > img {
  width: 200px;
  padding-left: 4px;
}
.datgridCard {
  background-color: transparent;
  box-shadow: none;
}
.datgridCard .MuiDataGrid-row {
  background-color: transparent;
  /*  padding-bottom: 32px ; */
}
.datgridCard .MuiDataGrid-row:hover {
  background-color: transparent;
}
.datgridCard .MuiDataGrid-cell {
  padding: 0;
  border-bottom: none;
}
.datgridCard .MuiDataGrid-cell .dgCard {
  box-shadow: 0 0 2px 0 rgba(145, 158, 171, 0.24),
    0 16px 8px -4px rgba(145, 158, 171, 0.24);
  background-color: white;
  padding: 16px;
  border-radius: 16px;
  margin-bottom: 16px;
}
/*CSS för Hjälp -------------------------------*/
.helpContainer {
  display: flex;
  flex-direction: column;
  /* padding: 24px; */
  padding: 24px;
  padding-bottom: 0;
  gap: 16px;
  justify-content: flex-start;
  background-color: #fafafa;
  flex-grow: 1;
}
.helpContainer > .stdFlexRow {
  margin-bottom: 0;
  margin-top: 0;
}
.helpList {
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 0 2px 0 rgba(145, 158, 171, 0.24),
    0 16px 32px -4px rgba(145, 158, 171, 0.24);
  padding-top: 0;
  padding-bottom: 0;
}

.helpList:empty {
  display: none;
}
.helpList > li {
  color: #006ec8;
  border-bottom: 1px solid rgba(145, 158, 171, 0.24);
  cursor: pointer;
}
.helpList > li:hover {
  background-color: rgba(145, 158, 171, 0.08);
}
.helpList > li:last-child {
  border-bottom: none;
}
.helpCloseRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.helpContentBox {
  border: 1px solid #00000014;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  /* padding: 8px; */
  padding: 32px;
  box-shadow: 0 0 2px 0 rgba(145, 158, 171, 0.24),
    0 16px 32px -4px rgba(145, 158, 171, 0.24);
  font-size: 16px;
  line-height: 1.5;
}
.helpContainer em {
  /*Sökträffar*/
  font-weight: bold;
  font-style: italic;
}
/*
Tillgängliga taggar i Retool, alla skall stylas på samma sätt här och i Retool
p vid alla linebreaks
h1, h2
(b,strong), u, s, blockquote, pre (har class="ql-syntax"), 
span (inline style för color respektive background-color),
ol, li & ul, li
p med class ql-indent-1 till  ql-indent-8 
a
*/
.helpContentBox header {
  font-size: 32px;
  /* margin-bottom: 16px; */
  line-height: 1.2;
}
.helpContentBox h1 {
  font-size: 32px;
  /* margin-bottom: 16px; */
  line-height: 1.2;
}
.helpContentBox h2 {
  font-size: 24px;
  /* margin-bottom: 16px; */
  line-height: 1.2;
}
@media only screen and (max-width: 400px) {
  .helpContentBox {
    gap: 12px;
  }
  .helpContentBox,
  .helpContainer {
    padding: 16px;
    font-size: 14px;
  }
  .helpContentBox header,
  .helpContentBox h1 {
    font-size: 24px;
  }
  .helpContentBox h2 {
    font-size: 18px;
  }
  .helpContentBox h2 {
    font-size: 18px;
  }
}
.helpContentBox s {
  font-size: 12px;
  font-style: italic;
  text-decoration: none;
}

.helpContentBox em {
  font-style: italic;
}
.helpContentBox ul,
.helpContentBox ol {
  padding-left: 16px;
}
.helpContentBox p {
  /* padding-bottom: 16px; */
}

.helpContentBox img {
  padding-top: 16px;
  padding-bottom: 16px;
}
.helpContentBox [class^="ql-indent-"] {
  padding-left: 48px;
}

/* Img show fullsize on click */
.fullSizeImage {
  position: fixed;
  z-index: 1000000;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000000a1;
  top: 0;
  left: 0;
  cursor: pointer;
}
.fullSizeImage > img {
  max-height: 95%;
  max-width: 95%;
}
.helpContentBox img {
  cursor: pointer;
}
@media only screen and (max-width: 600px) {
  .helpContentBox img {
    cursor: none;
    pointer-events: none;
  }
}
/*---------------------------------------------*/
.flexBottomCenter {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  color: rgba(0, 0, 0, 0.6);
  background-color: #fafafa;
  font-size: 12px;
  padding-top: 16px;
  padding-bottom: 16px;
  margin: 0;
  align-items: center;
}

.stdMailToLink {
  font-size: 0.9em;
  /* color: inherit; */
  color: rgba(0, 0, 0, 0.6);
  /*  color: #006ec8; */
  text-decoration: underline;
  cursor: pointer;
}
.stdLink {
  font-size: 0.9em;
  color: inherit;
  text-decoration: underline;
  cursor: pointer;
}
.stdAccordion .MuiAccordionDetails-root {
  padding-left: 38px;
  padding-bottom: 16px;
  padding-top: 0;
  font-size: 12px;
  max-width: 400px;
}
.stdAccordion .MuiAccordionSummary-root {
  padding: 0;
}

.dialogAccordion .MuiAccordionSummary-root {
  padding-left: 42px;
  padding-right: 6px;
  padding-bottom: 0;
  padding-top: 30px;
}
.dialogAccordion .MuiAccordionDetails-root {
  padding-left: 42px;
  padding-right: 0;
  padding-bottom: 16px;
  padding-top: 0;
  font-size: 12px;
}
.MuiDialog-container .dialogAccordion.MuiAccordion-root {
  padding: 0;
  margin: 0;
}
.MuiDialog-container .MuiPaper-root.dialogAccordion::before {
  opacity: 0;
}

.MuiDataGrid-root {
  border: 0;
}
.helpPreloader {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  width: 100%;
  z-index: 1;
  opacity: 0.5;
  top: 260px;
}

/* Tabeller utan header text, expanderande kolumner, test */
.dgNoIconText .MuiDataGrid-cell > a:hover,
.dgNoIconText .MuiDataGrid-cell > div:hover {
  transition-property: all;
  transition-delay: 0.5s;
  transition-duration: 0.5s;
  position: absolute;
  padding: 10px;
  background-color: #bdbdbd;
  /*  color: white; */
  border-radius: 8px;
  max-height: 48px;
  min-width: 150px;
  z-index: 1;
}
.dgNoIconText .MuiDataGrid-cell > a:hover div,
.dgNoIconText .MuiDataGrid-cell > div:hover div {
  transition-delay: 0.5s;
  transition-duration: 0.5s;
  /* color: white !important; */
}

.dgNoIconText .MuiDataGrid-columnHeaderTitleContainerContent > div > div {
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  font-size: 14px;
  position: absolute;
  height: 24px;
  color: black;
  z-index: 1;
  top: 0px; /*-16*/
}
.dgNoIconText .MuiDataGrid-columnHeaderTitleContainerContent > div:hover > div {
  transition-property: opacity;
  transition-duration: 0.5s;
  opacity: 1;
}
@media only screen and (max-width: 1400px) and (min-width: 700px) {
  .dgDynIconText .MuiDataGrid-cell > a:hover,
  .dgDynIconText .MuiDataGrid-cell > div:hover {
    transition-property: all;
    transition-delay: 0.5s;
    transition-duration: 0.5s;
    position: absolute;
    padding: 10px;
    background-color: #bdbdbd;
    /* color: white; */
    border-radius: 8px;
    max-height: 48px;
    min-width: 150px;
    z-index: 1;
  }
  .dgDynIconText .MuiDataGrid-cell > a:hover div,
  .dgDynIconText .MuiDataGrid-cell > div:hover div {
    transition-delay: 0.5s;
    transition-duration: 0.5s;
    /* color: white !important; */
  }
  .textAlignLeft {
    text-align: left;
  }
  .dgDynIconText .MuiDataGrid-columnHeaderTitleContainerContent > div > div {
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    font-size: 14px;
    position: absolute;
    height: 24px;
    color: black;
    z-index: 1;
    top: 0px; /*-16*/
  }
  .dgDynIconText
    .MuiDataGrid-columnHeaderTitleContainerContent
    > div:hover
    > div {
    transition-property: opacity;
    transition-duration: 0.5s;
    opacity: 1;
  }
}

.floatLeft {
  width: 50%;
  float: left;
}

.duplicateActors {
  padding: 6px;
  border-radius: 8px;
  border: solid 1px rgba(0, 0, 0, 0.32);
}

.duplicateActors table {
  width: 100%;
  table-layout: fixed;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.duplicateActors tr:first-child {
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500;
  font-size: 16px;
}

.duplicateActors td {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.floatRight {
  width: 50%;
  float: right;
  border-left: solid gray;
  padding-left: 2em;
}

.selfRegFontSize {
  font-size: 40px;
}

.selfRegCode {
  font-size: 40px;
}

.maxWidthImageSelf {
  max-width: 15em;
  margin-left: -1em;
}

.qrForm {
  display: flex;
  flex-direction: column;
}

.selfRegContainer {
  display: flex;
}

.selfRegCol1 {
  flex: 1;
  margin-left: 10em;
}

.selfRegCol2 {
  flex: 2;
  border-left: solid gray;
}

.selfRegColMobile {
  flex: 1;
  margin-left: 2em;
}

.selfRegCol2Margin {
  margin-left: 2em;
}

.selfRegPadding {
  padding: 1em;
}

.MuiCallout-root.MuiCallout-warning {
  color: #1a2027;
  background-color: rgba(255, 249, 235, 0.6);
  border-color: #ffdc48;
}

.MuiCallout-root {
  padding: 16px;
  margin: 16px 0;
  border: 1px solid;
  border-radius: 10px;
}

.notificationSettingsBox {
  display: flex;
  flex-direction: column;
}
.notificationSettingsBox > div {
  padding: 16px;
  border-bottom: 1px solid rgba(145, 158, 171, 0.24);
  line-height: 1.5;
}
.notificationSettingsBox > div > span {
  /* padding-left: 8px; */
  display: block;
}
.notificationSettingsBox > div > span:last-of-type {
  /* padding-left: 8px; */
  /* display: inline-block; */
}
.notificationSettingsBox > div:last-child {
  padding: 16px;
  border-bottom: none;
  line-height: 1.5;
}
.notificationSettingsBox h2 {
  display: inline;
  line-height: 1;
}
.noteFloatRight {
  float: right;
  display: flex;
  width: 120px;
  justify-content: space-around;
  height: 24px;
  align-items: center;
}
.selectInlineText {
  margin-left: 8px;
  margin-right: 3px;
  height: 22px;
  border-radius: 4px;
}
.selectInlineText > div {
  overflow: inherit !important;
}

.stdLightLoader.MuiLoadingButton-loading.Mui-disabled {
  color: #006ec867;
  background-color: rgba(0, 110, 200, 0.08);
}

.stdBlueLoader.MuiLoadingButton-loading.Mui-disabled {
  color: #ffffff7d;
  background-color: #006ec882;
}
.stdLightLoader.MuiLoadingButton-loading.Mui-disabled
  .MuiLoadingButton-loadingIndicator {
  color: #006ec8;
}
.stdBlueLoader.MuiLoadingButton-loading.Mui-disabled
  .MuiLoadingButton-loadingIndicator {
  color: white;
}
.unLinkiFy {
  text-decoration: none;
  color: inherit;
}

.regularButtonWithIcon {
  min-width: 0px;
  padding: 5px 10px;
}
.stdList {
  display: flex;
  margin-bottom: 16px;
  padding: 8px;
  background-color: rgba(0, 0, 0, 0.04);
  justify-content: space-between;
  border-radius: 4px;
}
.smallRedWarning {
  color: #f05032;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  font-size: 12px;
}
.smallInfo {
  color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  font-size: 12px;
}
.dialogSubtitle {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 24px;
}
.topBottom8 {
  margin-top: 8px;
  margin-bottom: 8px;
}
.topBottom16 {
  margin-top: 16px;
  margin-bottom: 16px;
}
.dashedBoxCenter {
  border-radius: 4px;
  border: dashed 2px rgba(0, 0, 0, 0.12);
  padding: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 32px;
  min-height: 386px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.26);
  flex-direction: column;
  text-align: center;
}
.MuiGrid-item.userSettingsDetailsCol {
  max-width: 900px;
}

table.notificationTable {
  border-collapse: collapse;
}

.notificationTableRow {
  border-bottom: 1pt solid lightgray;
}

.notificationTableCell {
  padding: 1em 0 1em 0.3em;
}

.licenseLineDescription {
  display: inline;
  font-weight: 500;
  line-height: 2;
}

/* FullCalendar styling */
.fc .fc-event .fc-event-main {
  padding-left: 4px;
  padding-right: 4px;
}
/* ------------- */

.lostCardGradient {
  background-image: linear-gradient(
    to bottom,
    rgba(213, 217, 219, 0.616),
    rgba(231, 247, 244, 0)
  );
}

.pulse-button {
  position: relative;
  width: 60px;
  height: 60px;
  border: none;
  box-shadow: 0 0 0 0 lightgray;
  border-radius: 50%;
  background-color: #fafafa;

  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
  -webkit-animation: pulse 2s infinite cubic-bezier(0.66, 0, 0, 1);
  -moz-animation: pulse 2s infinite cubic-bezier(0.66, 0, 0, 1);
  -ms-animation: pulse 2s infinite cubic-bezier(0.66, 0, 0, 1);
  animation: pulse 2s infinite cubic-bezier(0.66, 0, 0, 1);
}
.pulse-button:hover {
  -webkit-animation: none;
  -moz-animation: none;
  -ms-animation: none;
  animation: none;
}
@-webkit-keyframes pulse {
  to {
    box-shadow: 0 0 0 20px rgba(232, 76, 61, 0);
  }
}

.simpleCards {
  /* background-color: red; */
  padding: 8px;
}

.simpleCardStyle,
.simpleCards > div {
  background-color: #fff;
  color: #212b36;
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 8px;
  box-shadow: none;
  background-image: none;
  box-shadow: 1px;
  overflow: hidden;
  box-shadow: 0 0 2px 0 rgba(145, 158, 171, 0.24),
    0 16px 32px -4px rgba(145, 158, 171, 0.24);
  border-radius: 8px;
  position: relative;
  z-index: 0;
  padding: 16px;
  margin-bottom: 16px;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  display: flex;
  flex-direction: column;
}
.simpleCards > div > div {
  display: flex;
  justify-content: flex-start;
  gap: 16px;
  align-items: center;
  line-break: anywhere;
  text-align: left;
}
.simpleCards > div > div.flex_End {
  justify-content: flex-end;
}
.simpleCards > div > div.flex_space_Between {
  justify-content: space-between;
}
.simpleCards > div > div.flex_Center {
  justify-content: center;
}

@-moz-keyframes pulse {
  to {
    box-shadow: 0 0 0 20px rgba(232, 76, 61, 0);
  }
}
@-ms-keyframes pulse {
  to {
    box-shadow: 0 0 0 20px rgba(232, 76, 61, 0);
  }
}
@keyframes pulse {
  to {
    box-shadow: 0 0 0 20px rgba(232, 76, 61, 0);
  }
}

/* tableAndCard */
.tableAndCard .MuiDataGrid-withBorderColor,
.tableAndCard .MuiDataGrid-row,
.tableAndCard .MuiDataGrid-row:hover {
  background-color: transparent !important;
  border-color: transparent !important;
}

.tableAndCard .MuiDataGrid-row,
.tableAndCard .MuiDataGrid-row > .MuiDataGrid-cell {
  max-height: unset !important;
  width: 100%;
}
.tableAndCard .MuiDataGrid-row > .MuiDataGrid-cell > div {
  width: 100%;
  margin-top: 8px;
  margin-bottom: 8px;
}
.tableAndCard .MuiDataGrid-cell {
  display: none;
}
.tableAndCard .MuiDataGrid-cell:first-child {
  padding-left: 0px;
  display: flex;
  max-width: 100% !important;
  min-width: 100% !important;
}

.tableAndCard .MuiDataGrid-cell {
  padding: 0px;
}

.tableAndCard.dgNoFilter .MuiDataGrid-columnHeaders {
  display: none;
}
.tableAndCard .MuiDataGrid-columnHeader {
  width: auto !important;
  max-width: unset !important;
  min-width: auto !important;
}

.tableAndCard .MuiDataGrid-columnHeaderTitleContainerContent > div > div {
  display: none;
}
.tableAndCard .MuiDataGrid-virtualScrollerRenderZone {
  width: 100%;
}

.dgDynIconText .MuiDataGrid-cell > div:hover {
  max-height: 70px;
}

.forceFooter {
  min-height: calc(100vh - 284px);
}

/* .pageFooter {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: calc(100vw);
  height: 144px;
  position: relative;
  right: 24px;
  margin-top: 24px;
  background-color: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.6);
  padding: 16px;
} */
.pageFooter {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: calc(100vw);
  height: 144px;
  position: relative;
  right: 0px;
  margin-top: 24px;
  background-color: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.6);
  padding: 16px;
  position: absolute;
}

/* @media only screen and (max-width:1000px) {
  .pageFooter {
    flex-direction: column;
  }
}
@media only screen and (max-width: 600px) {
  .pageFooter {
    right: 8px;
    height: 180px;
  }
} */
.pageFooter > div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  max-width: 80%;
}

.pageFooter a,
.pageFooter a:visited {
  text-decoration: none;
  color: #006ec8;
}

.centerCard {
  /*  min-height: 200px; */
  /* display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center; */
}
.centerCardContent {
  padding-top: 0;
  margin-left: 40px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: space-between;
  min-height: 150px;
}

.permitRules {
  background-image: url("../public/static/riktlinjer.png");
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 240px;
  border-radius: 4px;
}
.permitRules > div {
  flex-grow: 2;
}
.permitRules .prBlurArea {
  clip-path: polygon(0 1%, 100% 0%, 100% 100%, 10% 100%);
  background-color: #00000096;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  padding-left: 100px;
  flex-grow: 1;
}
.prBlurArea > div {
  margin-left: 20px;
  color: white;
  max-width: 400px;
  font-size: 24px;
}
.prBlurArea > div:first-child {
  font-size: 12px;
  text-transform: uppercase;
  opacity: 0.5;
}
@media only screen and (max-width: 600px) {
  .permitRules {
    background-image: url("../public/static/riktlinjer.png");
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 425px;
  }
  .permitRules .prBlurArea {
    clip-path: polygon(0 0, 100% 16%, 100% 100%, 0% 100%);
    height: 60%;
    width: 100%;
    padding-left: 32px;
  }
}
.twoRowOptions {
}
.twoRowOptions > div:not(:first-child) {
  font-size: 12px;
  font-style: italic;
}
/*Validate permits start*/
.simpleLogoTop {
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 32px;
}
.simpleLogoTopImg {
  width: 400px;
}
.pageWithFooter {
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 16px;
  justify-content: space-between;
  box-shadow: 0 0 2px 0 rgba(145, 158, 171, 0.24),
    0 16px 32px -4px rgba(145, 158, 171, 0.24);
  border-radius: 8px;
  width: 100%;
  min-height: 100%;
}

.pageWithFooter div:empty {
  flex-grow: 1;
}
.pageWithFooter hr {
  color: rgba(0, 0, 0, 0.12);
  width: 100%;
}
.footerSpaceBetween hr {
  display: none;
}
.headerSpaceBetween,
.footerSpaceBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding: 8px;
  color: rgba(0, 0, 0, 0.26);
}
.headerSpaceBetween {
  font-size: 24px;
  color: rgba(0, 0, 0, 0.87);
}
.footerSpaceBetween .fsbLeft {
  display: flex;
  align-items: center;
  flex-direction: row;
  font-size: 16px;
  gap: 16px;
}
.footerSpaceBetween .fsbRight {
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 12px;
  gap: 4px;
}
@media only screen and (max-width: 600px) {
  .footerSpaceBetween {
    flex-direction: column;
  }
  .footerSpaceBetween hr {
    display: block;
  }
  .footerSpaceBetween .fsbLeft {
    flex-direction: column;
  }
  .headerSpaceBetween {
    font-size: 16px;
  }
}
/*Validate permits end*/

.hideOverlayWrapper .MuiDataGrid-overlayWrapperInner,
.hideOverlayWrapper .MuiDataGrid-virtualScrollerContent {
  display: none;
}

.showWhenMenuOpen {
  display: none;
}
.MuiPopover-root .showWhenMenuOpen {
  display: block;
}

.printView {
  display: none;
}
@media print {
  .printView {
    display: block;
  }
}

.stUnitListItemFieldLabel {
  font-weight: bold;
}

.stUnitField {
  border: solid 1px rgba(0, 0, 0, 0.23);
  border-radius: 100px;
  padding: 4px 10px;
}
.stUnitStatus {
  width: 12px;
  height: 12px;
  border-radius: 100%;
}
.stUnitStatus.ok {
  background-color: green;
}
.stUnitStatus.blocked {
  background-color: red;
}

.afterPickActionContainer {
  border: 1px dashed #637381;
  border-radius: 10px;
}
.afterPickAction {
  padding: 10px 12px;
}
.afterPickActionAltDivider {
  width: 100%;
  font-size: small;
  text-align: center;
  border-bottom: 1px dashed #637381;
  line-height: 0.1em;
  /* margin: 10px 0; */
}
.afterPickActionAltDivider span {
  background: #fff;
  padding: 0 5px;
}

.orderGridDetailsContainer {
  background-color: #f3f3f3;
}

.orderGridDetailsItem div {
  background-color: #fafafa;
}

.tooltipField {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dlWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}
.dlHeader {
  font-size: 32px;
  font-weight: 400;
}
.dlFilter {
  display: flex;
  padding: 16px;
  gap: 16px;
  width: 100%;
  flex-wrap: wrap;
  align-items: flex-start;
  max-width: 940px;
}
.dlCard {
  display: flex;
  padding: 24px;
  gap: 16px;
  box-shadow: 0 0 2px 0 rgba(145, 158, 171, 0.24),
    0 16px 32px -4px rgba(145, 158, 171, 0.24);
  border-radius: 8px;
  width: 100%;
  flex-wrap: wrap;
  align-items: start;
  max-width: 940px;
  justify-content: center;
}
.dlCard.clickable {
  cursor: pointer;
}
.dlCardIcon {
  flex-grow: 1;
  max-width: 730px;
  width: 700px;
}
.dlCardIcon > div {
  display: flex;
  justify-content: flex-start;
  gap: 16px;
  align-items: center;
  text-align: left;
}
.dlCardButtonBox {
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex-wrap: wrap;
}
.dlCardButtonBox button {
  min-width: 140px;
  min-height: 39px;
}

.dlCardButtonBox button.walletButton {
  padding: 0;
}
.dlCardButtonBox button.walletButton img {
  width: 140px;
}
.disabledWalletButton {
  opacity: 0.5;
}
@media only screen and (max-width: 985px) {
  .dlCardButtonBox {
    flex-direction: row;
  }
}

@media only screen and (max-width: 760px) {
  .dlCardButtonBox.dlAlignCard {
    max-width: 330px;
  }
}
@media only screen and (max-width: 500px) {
  .dlCard {
    padding: 8px;
  }
}

.grayscaleFilter {
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
}

.digitalLicenseBgImage {
  opacity: 0.3;
}

.digitalLicenseCompanyLogo {
  border-radius: 30px;
  width: 60px;
  height: 60px;
}

.checkoutSectionCard {
  padding: 12px;
}

.checkoutSectionHeader {
  margin-top: 26px;
  margin-bottom: 16px;
}

.iconTextRows {
  display: flex;
  flex-direction: column;
  /* gap: 32px;  tycker det är för mycket*/
  gap: 16px;
}
.iconTextRows > div {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}
/* ------- DL card  + Swiper -------- */

.dlcCard {
  display: flex;
  gap: 8px;
  position: relative;
  width: 664px;

}
.dlcCard > div {
  position: relative;
  width: 100%;
  background-color: slategray;
  border-radius: 15px;
}
/* .dlcFrontText {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 65px;
  left: 29px;
  color: white;
  gap: 26px;
} */
.dlcBackText,
.dlcFrontText {
	position: absolute;
	display: flex;
	flex-direction: column;
	top: 29px;
	left: 29px;
	color: white;
	gap: 26px;
	justify-content: space-between;
  padding-right: 8px;
  font-size: 12px;
  width: calc(100% - 58px);
  height: calc(100% - 58px);
}


.dlcFrontText > div > div:first-child,
.dlcBackText > div > div:first-child {
  text-transform: uppercase;
  font-size: 12px;
  color: #ffffff80;
}
.dlcBackText > div:last-child {
  flex-grow: 1;
}
.dlcBgImg{
  width:327px;
  height: 535px;
}
.dlcBgImg.expired {
  filter: grayscale(80%) brightness(0.9);
}

/* .dlcFrontText > div > div:first-child {
  text-transform: uppercase;
  font-size: 12px;
  color: #ffffff80;
}
.dlcFrontText > div > div:nth-child(3) {
  font-size: 12px;
  color: #ffffff80;
} */
/* .dlcFrontTextSpacer {
  height: 124px;
} */
.dlcGap {
 flex-grow: 1;
} 
.dlcMainTitle {
  font-size: 48px;
}
.dlcBackText > .dlcQrCodeContainer {
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: flex-end;
  margin-bottom: 6px;
}

/* .dlcBackText {
  position: absolute;
  top: 29px;
  left: 29px;
  display: flex;
  flex-direction: column;
  gap: 26px;
  font-size: 12px;
  color: white;
  padding-right: 8px;
} */

.dlcBackText > div {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.dlcBackText > div > div {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
}
.dlcBackText > div > div:first-child {
  text-transform: uppercase;
  font-size: 12px;
  color: #ffffff80;
}
.dlcInfoIcon {
  font-size: 20px;
}

.swiperDL {
    width: 327px;
   height: 535px;
    border-radius: 15px;
}
.swiperDLSlide {
  border-radius: 15px;
  background-color: slategray;
}


.dlcCardWrapper {
  display: flex;
  justify-content: center;
  margin: 16px;
  min-width: 608px;
  height: 535px;
}
.dlcPageWrapper {
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin: auto;
  gap: 24px;
}
@media only screen and (max-width: 1245px) {
  .dlcPageWrapper {
    max-width: 800px;
  }
}
@media only screen and (max-width: 839px) {
  .dlcPageWrapper {
    max-width: 384px;
  }
}

.dlcPage {
  display: flex;
  justify-content: center;
  background-color: #fafafa;
  gap: 24px;
  flex-wrap: wrap;
}

.dlcPage > div:last-child {
  flex-grow: 1;
  height: 0px;
  width: 384px;
}
.dlcPreCard {
  display: flex;
  flex-direction: column;
  /* width: 384px; */
  /* height: 688px; */
  /* height: 656px; */
  gap: 48px;
  border-radius: 4px;
  padding: 24px;
  background-color: white;
  box-shadow: 0 0 2px 0 rgba(145, 158, 171, 0.24),
    0 16px 32px -4px rgba(145, 158, 171, 0.24);
}
@media only screen and (max-width: 385px) {
  .dlcPreCard {
    padding: 16px;
    
  }
}
@media only screen and (max-height: 775px) {
  .dlcPreCard {
    gap: 16px;
    
  }
}
.dlcSmallCard {
  position: relative;
  display: flex;
  gap: 8px;
  position: relative;
  width: 100%;
  zoom: 0.5;
}
.dlcSmallCard > div {
  position: relative;
  /* width: 100%; */
  background-color: slategray;
  border-radius: 15px;
  width: 327px;
  height: 535px;
}
.dlcSmallCard .selectable{
  pointer-events: none;
}
/* Fade in */
.dlcSmallCard,.dlSingleContainer {
  animation: fadeInAnimation ease 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
      opacity: 0;
  }

  100% {
      opacity: 1;
  }
}
/* Fade in end */

.dlcAnimation {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  opacity: 0.5;
}
.dlcCompanyDialog{
  display: flex;
}
.dlcCompanyLogo{
  height: 32px;
}
.dlcProfilePic {
  width: 110px;
  height: 110px;
  border-radius: 55px;
}
.dlcProfilePicContainer {
  flex-grow: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.dlcCompanyDialog>div{
  width: 450px;
  display: flex;
  flex-direction: column;
  gap:16px;
  padding: 32px;
  flex-grow: 2;
  position: relative;
}
.dlcCompanyDialog>div:nth-child(odd)::after{
  content: '';
  position: absolute;
  right: -2px;
  top: 10%;
  width: 2px;
  height: 80%;
  background-color: rgba(0, 0, 0, 0.12);
}
.dlcCompetenceDialog {
	display: flex;
	gap: 16px;
}
.dlcCompetenceDialog > div {
	flex-grow: 1;
}
.dlcCompetenceDialog > div.dlcBlueBoxes {
border-right: 2px solid rgba(0, 0, 0, 0.12);
  padding-right: 16px;
}
.dlcCompetenceDialog > div.dlcBlueBoxes > div {
	padding: 10px;
	border-radius: 4px;
	background-color: rgba(0, 110, 200, 0.1);
	border: 1px solid rgba(0, 110, 200, 0.87);
  color: rgba(0, 110, 200, 0.87);
}
.dlcCompetenceDialog > div.dlcGrayBoxes > div {
	padding: 10px;
	border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.04);
  border: 1px solid rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.6);
}


.dlcCompetenceDialog > div > div.dlcTitle {
 border:none;
 background-color: inherit;
 padding: 0;
 text-transform: uppercase;
 color: rgba(0, 0, 0, 0.6);
}
.dlcCompetenceDialog > div.dlcBlueBoxes > div.dlcTitle:not(:first-child) {
  margin-top: 14px;
}

@media only screen and (max-width: 700px) {
  .dlcCompanyDialog {
    display: flex;
    flex-direction: column;
  }
  .dlcCompanyDialog>div:nth-child(odd)::after{
    display: none;
  }
  .dlcCompanyDialog > div {
    padding: 8px;
    width: auto;
  }
  .dlcCompanyDialog  .iconTextRows {
    border-top: 2px solid rgba(0, 0, 0, 0.12);
    padding-top:8px;
  }
  .dlcCompetenceDialog {
    flex-direction: column;
  }
  .dlcCompetenceDialog > div.dlcBlueBoxes {
    border-right:none;
      padding-right: 0;
  }
  .dlcCompetenceDialog > div.dlcBlueBoxes > div.dlcTitle:not(:first-child),
  .dlcCompetenceDialog > div.dlcGrayBoxes > div.dlcTitle {
    margin-top: 14px;
  }
}





.dlcHeading1 {
  font-size: 24px;
}
.dlcHeading2 {
  font-size: 20px;
}
/* ------- /DLC card  + Swiper -------- */

.redIcon {
  color: red;
}
.MuiIconButton-root.Mui-disabled .redIcon {
  color: #FF000024;
}

.receiptPageContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-bottom: 200px;
  gap: 20px;
}

.inOrderField {
  border: solid 1px rgba(0, 0, 0, 0.23);
  border-radius: 5px;
  padding: 4px 10px;
}

.Mui-disabled svg.endAdornmentObservationNote,
.endAdornmentObservationNote {
  color: red;
}