.imgCrop {
 
  /* padding: 16px; */
  max-width: 600px;
}

.imgCrop .Crop-Controls {
  margin-bottom: 10px;
}
.imgCrop .Crop-Controls > * {
  margin-bottom: 3px;
}

.imgCrop img {
  max-width: 100%;
}
.imgCrop .centerCenter{
  display: flex;
  justify-content:center;
  align-items:center;
  gap:8px;

}
.imgCrop .alignRight{
  display: flex;
  justify-content:end;
  align-items:center;
  gap:8px;

}
.imgCrop .scaleslider{
  width: 80%;
  }

  @media only screen and (max-width: 700px) {
    .twoColForm .imgCrop .Crop-Controls {
    margin-bottom: 0;
  }
  .twoColForm .imgCrop {
    padding: 8px;
    max-width: 600px;
    position: absolute;
    z-index: 100;
    height: 100vh;
    background-color: white;
    top: 0;
  }
}
/* Safari ios hack*/
@supports (-webkit-hyphens:none) {
  @media only screen and (max-width: 500px) {
  .twoColForm .imgCrop { 
        /* color:red; 
        background-color:red;  */
        height: 70vh;
    }
  }
}